export enum PlaylistTypes {
  Default = 'default',
  Generated = 'generated',
  New4U = 'new4u',
  Personalized = 'personalized',
  User = 'user',
}

export const FOLLOW_TEXT = 'Add Playlist to Library';
export const REMOVE_TEXT = 'Remove Playlist from Library';
